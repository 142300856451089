
.filter-btn{
    &.v-btn:not(.v-btn--fab,.v-btn--icon).v-size--default{
        height: 40px;
        font-size: 1.4rem;
        padding: 0 10px 0 16px;
    }
}
.v-btn-toggle--primary{
    flex-wrap: wrap;
    display: flex;
    margin: -5px;
    .v-btn-wrap{
        padding: 5px;
        width: 33.33333%;
    }
}
::v-deep{
    .v-dialog{
        border-radius: 0;
        box-shadow: none;
    }
    .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
        border-color: var(--v-primary-base) !important;
        font-weight: 700;
        &.v-btn--active{
            background-color: var(--v-primary-base) !important;
            color: #fff !important;
        }
    }
}
.dialog{
    &__inner{
        position: relative;
        >.v-btn.v-btn--absolute.v-btn--top.v-btn--absolute.v-btn--right{
            top: 0;
            right: 0;
        }
    }
    &__contents{
        background-color: #fff;
        padding: 40px 20px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .dialog{
        &__contents{
            padding: 60px 60px 100px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

